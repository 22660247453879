// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: integration_mqtt.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  BytesOrHex,
  Duration,
  Filter_Events,
  Filter_Events_Update,
  Filter_Modems,
  Filter_Modems_Update,
  Filter_Publishers,
  Filter_Tags,
  Filter_Tags_Update,
  Health,
  healthFromJSON,
  healthToNumber,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
  UpdateBoolean,
  UpdateClearableString,
  UpdateOptionalId,
  UpdateZeroableInt,
} from "./base";
import { Tag, TagSelection, UpdateTagsForItem } from "./tag";

export const protobufPackage = "hiber.integration.mqtt";

export interface MQTTPublisher {
  id: number;
  description: string;
  /** The uid of the user that created this publisher */
  createdBy: string;
  data: MQTTPublisher_Data | undefined;
  filterEventTypes: Filter_Events | undefined;
  filterModemNumbers: Filter_Modems | undefined;
  filterTags: Filter_Tags | undefined;
  health: Health;
  healthConfig: MQTTPublisher_HealthConfig | undefined;
  tags: Tag[];
}

export enum MQTTPublisher_ContentType {
  DEFAULT = "DEFAULT",
  JSON = "JSON",
  PROTO = "PROTO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function mQTTPublisher_ContentTypeFromJSON(object: any): MQTTPublisher_ContentType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return MQTTPublisher_ContentType.DEFAULT;
    case 1:
    case "JSON":
      return MQTTPublisher_ContentType.JSON;
    case 2:
    case "PROTO":
      return MQTTPublisher_ContentType.PROTO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MQTTPublisher_ContentType.UNRECOGNIZED;
  }
}

export function mQTTPublisher_ContentTypeToNumber(object: MQTTPublisher_ContentType): number {
  switch (object) {
    case MQTTPublisher_ContentType.DEFAULT:
      return 0;
    case MQTTPublisher_ContentType.JSON:
      return 1;
    case MQTTPublisher_ContentType.PROTO:
      return 2;
    case MQTTPublisher_ContentType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface MQTTPublisher_Data {
  /** The url of an MQTT server to send the events to, e.g. mqtt[s]://example.com:8883 */
  url: string;
  contentType: MQTTPublisher_ContentType;
  /** The MQTT topic to send to on the receiving server. */
  topic: string;
  /** MQTT QoS value. */
  qos: MQTTPublisher_Data_QoS;
  /** Identifier used by the MQTT client. Defaults to "hiber". */
  identifier: string;
  /** Optional username to authenticate with. */
  username: string;
  /** Optional password to authenticate with. Requires username to be set. */
  password: string;
  /** Client certificate to use when connecting to the MQTT server. */
  certificateId: number;
  certificateName: string;
  /** CA certificate for the client certificate to use when connecting to the MQTT server. */
  caCertificateId: number;
  caCertificateName: string;
  /** Server CA certificate to use when connecting to the MQTT server. */
  serverCaCertificateId: number;
  serverCaCertificateName: string;
  disabled: boolean;
}

export enum MQTTPublisher_Data_QoS {
  DEFAULT = "DEFAULT",
  /** AT_MOST_ONCE - Qos level 0 */
  AT_MOST_ONCE = "AT_MOST_ONCE",
  /** AT_LEAST_ONCE - Qos level 1 */
  AT_LEAST_ONCE = "AT_LEAST_ONCE",
  /** EXACTLY_ONCE - Qos level 2 */
  EXACTLY_ONCE = "EXACTLY_ONCE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function mQTTPublisher_Data_QoSFromJSON(object: any): MQTTPublisher_Data_QoS {
  switch (object) {
    case 0:
    case "DEFAULT":
      return MQTTPublisher_Data_QoS.DEFAULT;
    case 1:
    case "AT_MOST_ONCE":
      return MQTTPublisher_Data_QoS.AT_MOST_ONCE;
    case 2:
    case "AT_LEAST_ONCE":
      return MQTTPublisher_Data_QoS.AT_LEAST_ONCE;
    case 3:
    case "EXACTLY_ONCE":
      return MQTTPublisher_Data_QoS.EXACTLY_ONCE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MQTTPublisher_Data_QoS.UNRECOGNIZED;
  }
}

export function mQTTPublisher_Data_QoSToNumber(object: MQTTPublisher_Data_QoS): number {
  switch (object) {
    case MQTTPublisher_Data_QoS.DEFAULT:
      return 0;
    case MQTTPublisher_Data_QoS.AT_MOST_ONCE:
      return 1;
    case MQTTPublisher_Data_QoS.AT_LEAST_ONCE:
      return 2;
    case MQTTPublisher_Data_QoS.EXACTLY_ONCE:
      return 3;
    case MQTTPublisher_Data_QoS.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Health configuration for the mqtt integration. Defines how the health is calculated. */
export interface MQTTPublisher_HealthConfig {
  /** Period to consider when determining health from warning events. Warning events cannot be resolved. */
  healthWarningPeriod:
    | Duration
    | undefined;
  /**
   * Allowed percentage of failures.
   * If the failure percentage is higher, within the warning period, the health is switched to WARNING.
   */
  healthWarningFailurePercentage: number;
}

export interface MQTTMessage {
  time: Timestamp | undefined;
  publisherData: MQTTPublisher_Data | undefined;
  message: BytesOrHex | undefined;
  successful: boolean;
  error: string;
}

export interface MQTTPublisherSelection {
  /** Filter by id. */
  publishers?:
    | Filter_Publishers
    | undefined;
  /** Partial text match on the description. */
  description?:
    | string
    | undefined;
  /** Partial text match on the url. */
  searchUrl?:
    | string
    | undefined;
  /** Partial text match on the topic. */
  searchTopic?:
    | string
    | undefined;
  /** Only return MQTT integrations that use the given content types. */
  contentTypes: MQTTPublisher_ContentType[];
  /** Filter by referenced certificate (id), either as client or server certificate. */
  certificateIds: number[];
  tags?: TagSelection | undefined;
  health: Health[];
}

export interface ListMQTTPublishersRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection?: MQTTPublisherSelection | undefined;
  pagination?: Pagination | undefined;
  sort: ListMQTTPublishersRequest_Sort[];
}

export enum ListMQTTPublishersRequest_Sort {
  /** DEFAULT - Sort by id. */
  DEFAULT = "DEFAULT",
  /** ID_DESC - Sort by id, high to low. */
  ID_DESC = "ID_DESC",
  /** DESCRIPTION - Sort by description. */
  DESCRIPTION = "DESCRIPTION",
  /** DESCRIPTION_DESC - Sort by description, z to a. */
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  /** URL - Sort by url. */
  URL = "URL",
  /** URL_DESC - Sort by url, z to a. */
  URL_DESC = "URL_DESC",
  /** TOPIC - Sort by topic. */
  TOPIC = "TOPIC",
  /** TOPIC_DESC - Sort by topic, z to a. */
  TOPIC_DESC = "TOPIC_DESC",
  /** HEALTH - Sort unhealthy webhooks before health webhooks. */
  HEALTH = "HEALTH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listMQTTPublishersRequest_SortFromJSON(object: any): ListMQTTPublishersRequest_Sort {
  switch (object) {
    case 0:
    case "DEFAULT":
      return ListMQTTPublishersRequest_Sort.DEFAULT;
    case 1:
    case "ID_DESC":
      return ListMQTTPublishersRequest_Sort.ID_DESC;
    case 2:
    case "DESCRIPTION":
      return ListMQTTPublishersRequest_Sort.DESCRIPTION;
    case 3:
    case "DESCRIPTION_DESC":
      return ListMQTTPublishersRequest_Sort.DESCRIPTION_DESC;
    case 4:
    case "URL":
      return ListMQTTPublishersRequest_Sort.URL;
    case 5:
    case "URL_DESC":
      return ListMQTTPublishersRequest_Sort.URL_DESC;
    case 6:
    case "TOPIC":
      return ListMQTTPublishersRequest_Sort.TOPIC;
    case 7:
    case "TOPIC_DESC":
      return ListMQTTPublishersRequest_Sort.TOPIC_DESC;
    case 8:
    case "HEALTH":
      return ListMQTTPublishersRequest_Sort.HEALTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListMQTTPublishersRequest_Sort.UNRECOGNIZED;
  }
}

export function listMQTTPublishersRequest_SortToNumber(object: ListMQTTPublishersRequest_Sort): number {
  switch (object) {
    case ListMQTTPublishersRequest_Sort.DEFAULT:
      return 0;
    case ListMQTTPublishersRequest_Sort.ID_DESC:
      return 1;
    case ListMQTTPublishersRequest_Sort.DESCRIPTION:
      return 2;
    case ListMQTTPublishersRequest_Sort.DESCRIPTION_DESC:
      return 3;
    case ListMQTTPublishersRequest_Sort.URL:
      return 4;
    case ListMQTTPublishersRequest_Sort.URL_DESC:
      return 5;
    case ListMQTTPublishersRequest_Sort.TOPIC:
      return 6;
    case ListMQTTPublishersRequest_Sort.TOPIC_DESC:
      return 7;
    case ListMQTTPublishersRequest_Sort.HEALTH:
      return 8;
    case ListMQTTPublishersRequest_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListMQTTPublishersRequest_Response {
  publishers: MQTTPublisher[];
  request: ListMQTTPublishersRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface MQTTPublisherHistorySelection {
  timeRange?: TimeRange | undefined;
  onlyFailures?: boolean | undefined;
}

export interface MQTTPublisherHistoryRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
  selection?: MQTTPublisherHistorySelection | undefined;
  pagination?: Pagination | undefined;
}

export interface MQTTPublisherHistoryRequest_Response {
  items: MQTTMessage[];
  request: MQTTPublisherHistoryRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface CreateMQTTPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  description?: string | undefined;
  filterEventTypes?: Filter_Events | undefined;
  filterModemNumbers?: Filter_Modems | undefined;
  filterTags?:
    | Filter_Tags
    | undefined;
  /** The url of an MQTT server to send the events to, e.g. mqtt[s]://example.com:8883 */
  url: string;
  contentType: MQTTPublisher_ContentType;
  /** The MQTT topic to send to on the receiving server. */
  topic: string;
  /** MQTT QoS value. */
  qos: MQTTPublisher_Data_QoS;
  /** Identifier used by the MQTT client. Defaults to "hiber". */
  identifier?:
    | string
    | undefined;
  /** Optional username to authenticate with. */
  username?:
    | string
    | undefined;
  /** Optional password to authenticate with. Requires username to be set. */
  password?:
    | string
    | undefined;
  /** Client certificate to use when connecting to the MQTT server. */
  certificateId?:
    | number
    | undefined;
  /** Server CA certificate to use when connecting to the MQTT server. */
  serverCaCertificateId?:
    | number
    | undefined;
  /** Disable the MQTT publisher after creation, so it needs to be enabled before it is active. */
  disabled?: boolean | undefined;
  tags: number[];
  /** Set this flag to not parse the url or enforce mqtts. */
  overrideUrlValidation?: boolean | undefined;
}

/** Enable a disabled publisher or re-enable a publisher that's failed and is in cooldown. */
export interface EnableMQTTPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection: MQTTPublisherSelection | undefined;
}

export interface EnableMQTTPublisherRequest_Response {
  publishers: MQTTPublisher[];
  request: EnableMQTTPublisherRequest | undefined;
}

export interface DisableMQTTPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection: MQTTPublisherSelection | undefined;
}

export interface DisableMQTTPublisherRequest_Response {
  publishers: MQTTPublisher[];
  request: DisableMQTTPublisherRequest | undefined;
}

export interface UpdateMQTTPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
  /** @deprecated */
  deprecatedFilterEventTypes?: Filter_Events_Update | undefined;
  filterEventTypes?:
    | Filter_Events
    | undefined;
  /** @deprecated */
  deprecatedFilterModemNumbers?: Filter_Modems_Update | undefined;
  filterModemNumbers?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  deprecatedFilterTags?: Filter_Tags_Update | undefined;
  filterTags?:
    | Filter_Tags
    | undefined;
  /** @deprecated */
  deprecatedDescription?: UpdateClearableString | undefined;
  description?:
    | string
    | undefined;
  /** The url of an MQTT server to send the events to, e.g. mqtt[s]://example.com:8883 */
  url?: string | undefined;
  contentType?:
    | MQTTPublisher_ContentType
    | undefined;
  /** The MQTT topic to send to on the receiving server. */
  topic?:
    | string
    | undefined;
  /** MQTT QoS value. */
  qos?:
    | MQTTPublisher_Data_QoS
    | undefined;
  /** Identifier used by the MQTT client. Defaults to "hiber". */
  identifier?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedIdentifier?:
    | UpdateClearableString
    | undefined;
  /** Optional username to authenticate with. */
  username?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedUsername?:
    | UpdateClearableString
    | undefined;
  /** Optional password to authenticate with. Requires username to be set. */
  password?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedPassword?:
    | UpdateClearableString
    | undefined;
  /** Update or remove the client certificate to use when connecting to the MQTT server. */
  certificateId?:
    | number
    | undefined;
  /** @deprecated */
  deprecatedCertificateId?:
    | UpdateOptionalId
    | undefined;
  /** Update or remove the server CA certificate to use when connecting to the MQTT server. */
  serverCaCertificateId?:
    | number
    | undefined;
  /** @deprecated */
  deprecatedServerCaCertificateId?:
    | UpdateOptionalId
    | undefined;
  /** Disable the MQTT publisher, so it needs to be enabled again before it is active. */
  active?:
    | boolean
    | undefined;
  /** @deprecated */
  deprecatedActive?:
    | UpdateBoolean
    | undefined;
  /**
   * Period to consider when determining health from warning events. Warning events cannot be resolved.
   * Set this to 0 to disable warnings based on failure percentage.
   */
  healthWarningPeriod?:
    | Duration
    | undefined;
  /**
   * Allowed percentage of call failures.
   * If the failure percentage is higher, within the warning period, the health is switched to WARNING.
   */
  healthWarningFailurePercentage?:
    | number
    | undefined;
  /** @deprecated */
  deprecatedHealthWarningFailurePercentage?:
    | UpdateZeroableInt
    | undefined;
  /** Set this flag to not parse the url or enforce mqtts. */
  overrideUrlValidation?: boolean | undefined;
}

export interface UpdateMQTTPublisherTagsRequest {
  organization?: string | undefined;
  selection: MQTTPublisherSelection | undefined;
  update: UpdateTagsForItem | undefined;
}

export interface UpdateMQTTPublisherTagsRequest_Response {
  mqttPublishers: MQTTPublisher[];
}

export interface DeleteMQTTPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
}

export interface DeleteMQTTPublisherRequest_Response {
}

function createBaseMQTTPublisher(): MQTTPublisher {
  return {
    id: 0,
    description: "",
    createdBy: "",
    data: undefined,
    filterEventTypes: undefined,
    filterModemNumbers: undefined,
    filterTags: undefined,
    health: Health.OK,
    healthConfig: undefined,
    tags: [],
  };
}

export const MQTTPublisher = {
  encode(message: MQTTPublisher, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.createdBy !== "") {
      writer.uint32(82).string(message.createdBy);
    }
    if (message.data !== undefined) {
      MQTTPublisher_Data.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterEventTypes !== undefined) {
      Filter_Events.encode(message.filterEventTypes, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterModemNumbers !== undefined) {
      Filter_Modems.encode(message.filterModemNumbers, writer.uint32(42).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(50).fork()).ldelim();
    }
    if (message.health !== Health.OK) {
      writer.uint32(56).int32(healthToNumber(message.health));
    }
    if (message.healthConfig !== undefined) {
      MQTTPublisher_HealthConfig.encode(message.healthConfig, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisher {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisher();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = MQTTPublisher_Data.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterEventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filterModemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.health = healthFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.healthConfig = MQTTPublisher_HealthConfig.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisher>): MQTTPublisher {
    return MQTTPublisher.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisher>): MQTTPublisher {
    const message = createBaseMQTTPublisher();
    message.id = object.id ?? 0;
    message.description = object.description ?? "";
    message.createdBy = object.createdBy ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? MQTTPublisher_Data.fromPartial(object.data)
      : undefined;
    message.filterEventTypes = (object.filterEventTypes !== undefined && object.filterEventTypes !== null)
      ? Filter_Events.fromPartial(object.filterEventTypes)
      : undefined;
    message.filterModemNumbers = (object.filterModemNumbers !== undefined && object.filterModemNumbers !== null)
      ? Filter_Modems.fromPartial(object.filterModemNumbers)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.health = object.health ?? Health.OK;
    message.healthConfig = (object.healthConfig !== undefined && object.healthConfig !== null)
      ? MQTTPublisher_HealthConfig.fromPartial(object.healthConfig)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMQTTPublisher_Data(): MQTTPublisher_Data {
  return {
    url: "",
    contentType: MQTTPublisher_ContentType.DEFAULT,
    topic: "",
    qos: MQTTPublisher_Data_QoS.DEFAULT,
    identifier: "",
    username: "",
    password: "",
    certificateId: 0,
    certificateName: "",
    caCertificateId: 0,
    caCertificateName: "",
    serverCaCertificateId: 0,
    serverCaCertificateName: "",
    disabled: false,
  };
}

export const MQTTPublisher_Data = {
  encode(message: MQTTPublisher_Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.contentType !== MQTTPublisher_ContentType.DEFAULT) {
      writer.uint32(16).int32(mQTTPublisher_ContentTypeToNumber(message.contentType));
    }
    if (message.topic !== "") {
      writer.uint32(26).string(message.topic);
    }
    if (message.qos !== MQTTPublisher_Data_QoS.DEFAULT) {
      writer.uint32(32).int32(mQTTPublisher_Data_QoSToNumber(message.qos));
    }
    if (message.identifier !== "") {
      writer.uint32(42).string(message.identifier);
    }
    if (message.username !== "") {
      writer.uint32(50).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(58).string(message.password);
    }
    if (message.certificateId !== 0) {
      writer.uint32(64).int64(message.certificateId);
    }
    if (message.certificateName !== "") {
      writer.uint32(82).string(message.certificateName);
    }
    if (message.caCertificateId !== 0) {
      writer.uint32(88).int64(message.caCertificateId);
    }
    if (message.caCertificateName !== "") {
      writer.uint32(98).string(message.caCertificateName);
    }
    if (message.serverCaCertificateId !== 0) {
      writer.uint32(104).int64(message.serverCaCertificateId);
    }
    if (message.serverCaCertificateName !== "") {
      writer.uint32(114).string(message.serverCaCertificateName);
    }
    if (message.disabled !== false) {
      writer.uint32(72).bool(message.disabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisher_Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisher_Data();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contentType = mQTTPublisher_ContentTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.topic = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qos = mQTTPublisher_Data_QoSFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.username = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.password = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.certificateName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.caCertificateId = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.caCertificateName = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.serverCaCertificateId = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.serverCaCertificateName = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.disabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisher_Data>): MQTTPublisher_Data {
    return MQTTPublisher_Data.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisher_Data>): MQTTPublisher_Data {
    const message = createBaseMQTTPublisher_Data();
    message.url = object.url ?? "";
    message.contentType = object.contentType ?? MQTTPublisher_ContentType.DEFAULT;
    message.topic = object.topic ?? "";
    message.qos = object.qos ?? MQTTPublisher_Data_QoS.DEFAULT;
    message.identifier = object.identifier ?? "";
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    message.certificateId = object.certificateId ?? 0;
    message.certificateName = object.certificateName ?? "";
    message.caCertificateId = object.caCertificateId ?? 0;
    message.caCertificateName = object.caCertificateName ?? "";
    message.serverCaCertificateId = object.serverCaCertificateId ?? 0;
    message.serverCaCertificateName = object.serverCaCertificateName ?? "";
    message.disabled = object.disabled ?? false;
    return message;
  },
};

function createBaseMQTTPublisher_HealthConfig(): MQTTPublisher_HealthConfig {
  return { healthWarningPeriod: undefined, healthWarningFailurePercentage: 0 };
}

export const MQTTPublisher_HealthConfig = {
  encode(message: MQTTPublisher_HealthConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(18).fork()).ldelim();
    }
    if (message.healthWarningFailurePercentage !== 0) {
      writer.uint32(24).int32(message.healthWarningFailurePercentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisher_HealthConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisher_HealthConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.healthWarningFailurePercentage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisher_HealthConfig>): MQTTPublisher_HealthConfig {
    return MQTTPublisher_HealthConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisher_HealthConfig>): MQTTPublisher_HealthConfig {
    const message = createBaseMQTTPublisher_HealthConfig();
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.healthWarningFailurePercentage = object.healthWarningFailurePercentage ?? 0;
    return message;
  },
};

function createBaseMQTTMessage(): MQTTMessage {
  return { time: undefined, publisherData: undefined, message: undefined, successful: false, error: "" };
}

export const MQTTMessage = {
  encode(message: MQTTMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(10).fork()).ldelim();
    }
    if (message.publisherData !== undefined) {
      MQTTPublisher_Data.encode(message.publisherData, writer.uint32(18).fork()).ldelim();
    }
    if (message.message !== undefined) {
      BytesOrHex.encode(message.message, writer.uint32(26).fork()).ldelim();
    }
    if (message.successful !== false) {
      writer.uint32(32).bool(message.successful);
    }
    if (message.error !== "") {
      writer.uint32(42).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.publisherData = MQTTPublisher_Data.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.successful = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTMessage>): MQTTMessage {
    return MQTTMessage.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTMessage>): MQTTMessage {
    const message = createBaseMQTTMessage();
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.publisherData = (object.publisherData !== undefined && object.publisherData !== null)
      ? MQTTPublisher_Data.fromPartial(object.publisherData)
      : undefined;
    message.message = (object.message !== undefined && object.message !== null)
      ? BytesOrHex.fromPartial(object.message)
      : undefined;
    message.successful = object.successful ?? false;
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseMQTTPublisherSelection(): MQTTPublisherSelection {
  return {
    publishers: undefined,
    description: undefined,
    searchUrl: undefined,
    searchTopic: undefined,
    contentTypes: [],
    certificateIds: [],
    tags: undefined,
    health: [],
  };
}

export const MQTTPublisherSelection = {
  encode(message: MQTTPublisherSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publishers !== undefined) {
      Filter_Publishers.encode(message.publishers, writer.uint32(10).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.searchUrl !== undefined) {
      writer.uint32(26).string(message.searchUrl);
    }
    if (message.searchTopic !== undefined) {
      writer.uint32(34).string(message.searchTopic);
    }
    writer.uint32(42).fork();
    for (const v of message.contentTypes) {
      writer.int32(mQTTPublisher_ContentTypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.certificateIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.tags !== undefined) {
      TagSelection.encode(message.tags, writer.uint32(58).fork()).ldelim();
    }
    writer.uint32(66).fork();
    for (const v of message.health) {
      writer.int32(healthToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisherSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisherSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers = Filter_Publishers.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.searchTopic = reader.string();
          continue;
        case 5:
          if (tag === 40) {
            message.contentTypes.push(mQTTPublisher_ContentTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.contentTypes.push(mQTTPublisher_ContentTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.certificateIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.certificateIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag === 64) {
            message.health.push(healthFromJSON(reader.int32()));

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.health.push(healthFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisherSelection>): MQTTPublisherSelection {
    return MQTTPublisherSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisherSelection>): MQTTPublisherSelection {
    const message = createBaseMQTTPublisherSelection();
    message.publishers = (object.publishers !== undefined && object.publishers !== null)
      ? Filter_Publishers.fromPartial(object.publishers)
      : undefined;
    message.description = object.description ?? undefined;
    message.searchUrl = object.searchUrl ?? undefined;
    message.searchTopic = object.searchTopic ?? undefined;
    message.contentTypes = object.contentTypes?.map((e) => e) || [];
    message.certificateIds = object.certificateIds?.map((e) => e) || [];
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagSelection.fromPartial(object.tags)
      : undefined;
    message.health = object.health?.map((e) => e) || [];
    return message;
  },
};

function createBaseListMQTTPublishersRequest(): ListMQTTPublishersRequest {
  return { organization: undefined, selection: undefined, pagination: undefined, sort: [] };
}

export const ListMQTTPublishersRequest = {
  encode(message: ListMQTTPublishersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      MQTTPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sort) {
      writer.int32(listMQTTPublishersRequest_SortToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMQTTPublishersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMQTTPublishersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = MQTTPublisherSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sort.push(listMQTTPublishersRequest_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sort.push(listMQTTPublishersRequest_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListMQTTPublishersRequest>): ListMQTTPublishersRequest {
    return ListMQTTPublishersRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMQTTPublishersRequest>): ListMQTTPublishersRequest {
    const message = createBaseListMQTTPublishersRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MQTTPublisherSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort?.map((e) => e) || [];
    return message;
  },
};

function createBaseListMQTTPublishersRequest_Response(): ListMQTTPublishersRequest_Response {
  return { publishers: [], request: undefined, pagination: undefined };
}

export const ListMQTTPublishersRequest_Response = {
  encode(message: ListMQTTPublishersRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.publishers) {
      MQTTPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListMQTTPublishersRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMQTTPublishersRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMQTTPublishersRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers.push(MQTTPublisher.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListMQTTPublishersRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListMQTTPublishersRequest_Response>): ListMQTTPublishersRequest_Response {
    return ListMQTTPublishersRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMQTTPublishersRequest_Response>): ListMQTTPublishersRequest_Response {
    const message = createBaseListMQTTPublishersRequest_Response();
    message.publishers = object.publishers?.map((e) => MQTTPublisher.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListMQTTPublishersRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseMQTTPublisherHistorySelection(): MQTTPublisherHistorySelection {
  return { timeRange: undefined, onlyFailures: undefined };
}

export const MQTTPublisherHistorySelection = {
  encode(message: MQTTPublisherHistorySelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim();
    }
    if (message.onlyFailures !== undefined) {
      writer.uint32(16).bool(message.onlyFailures);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisherHistorySelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisherHistorySelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onlyFailures = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisherHistorySelection>): MQTTPublisherHistorySelection {
    return MQTTPublisherHistorySelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisherHistorySelection>): MQTTPublisherHistorySelection {
    const message = createBaseMQTTPublisherHistorySelection();
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.onlyFailures = object.onlyFailures ?? undefined;
    return message;
  },
};

function createBaseMQTTPublisherHistoryRequest(): MQTTPublisherHistoryRequest {
  return { organization: undefined, id: 0, selection: undefined, pagination: undefined };
}

export const MQTTPublisherHistoryRequest = {
  encode(message: MQTTPublisherHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    if (message.selection !== undefined) {
      MQTTPublisherHistorySelection.encode(message.selection, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisherHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisherHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selection = MQTTPublisherHistorySelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisherHistoryRequest>): MQTTPublisherHistoryRequest {
    return MQTTPublisherHistoryRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisherHistoryRequest>): MQTTPublisherHistoryRequest {
    const message = createBaseMQTTPublisherHistoryRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MQTTPublisherHistorySelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseMQTTPublisherHistoryRequest_Response(): MQTTPublisherHistoryRequest_Response {
  return { items: [], request: undefined, pagination: undefined };
}

export const MQTTPublisherHistoryRequest_Response = {
  encode(message: MQTTPublisherHistoryRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      MQTTMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      MQTTPublisherHistoryRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MQTTPublisherHistoryRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMQTTPublisherHistoryRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(MQTTMessage.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = MQTTPublisherHistoryRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MQTTPublisherHistoryRequest_Response>): MQTTPublisherHistoryRequest_Response {
    return MQTTPublisherHistoryRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MQTTPublisherHistoryRequest_Response>): MQTTPublisherHistoryRequest_Response {
    const message = createBaseMQTTPublisherHistoryRequest_Response();
    message.items = object.items?.map((e) => MQTTMessage.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? MQTTPublisherHistoryRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCreateMQTTPublisherRequest(): CreateMQTTPublisherRequest {
  return {
    organization: undefined,
    description: undefined,
    filterEventTypes: undefined,
    filterModemNumbers: undefined,
    filterTags: undefined,
    url: "",
    contentType: MQTTPublisher_ContentType.DEFAULT,
    topic: "",
    qos: MQTTPublisher_Data_QoS.DEFAULT,
    identifier: undefined,
    username: undefined,
    password: undefined,
    certificateId: undefined,
    serverCaCertificateId: undefined,
    disabled: undefined,
    tags: [],
    overrideUrlValidation: undefined,
  };
}

export const CreateMQTTPublisherRequest = {
  encode(message: CreateMQTTPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.filterEventTypes !== undefined) {
      Filter_Events.encode(message.filterEventTypes, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterModemNumbers !== undefined) {
      Filter_Modems.encode(message.filterModemNumbers, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(42).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(82).string(message.url);
    }
    if (message.contentType !== MQTTPublisher_ContentType.DEFAULT) {
      writer.uint32(88).int32(mQTTPublisher_ContentTypeToNumber(message.contentType));
    }
    if (message.topic !== "") {
      writer.uint32(98).string(message.topic);
    }
    if (message.qos !== MQTTPublisher_Data_QoS.DEFAULT) {
      writer.uint32(104).int32(mQTTPublisher_Data_QoSToNumber(message.qos));
    }
    if (message.identifier !== undefined) {
      writer.uint32(114).string(message.identifier);
    }
    if (message.username !== undefined) {
      writer.uint32(122).string(message.username);
    }
    if (message.password !== undefined) {
      writer.uint32(130).string(message.password);
    }
    if (message.certificateId !== undefined) {
      writer.uint32(136).int64(message.certificateId);
    }
    if (message.serverCaCertificateId !== undefined) {
      writer.uint32(168).int64(message.serverCaCertificateId);
    }
    if (message.disabled !== undefined) {
      writer.uint32(144).bool(message.disabled);
    }
    writer.uint32(162).fork();
    for (const v of message.tags) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.overrideUrlValidation !== undefined) {
      writer.uint32(176).bool(message.overrideUrlValidation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMQTTPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMQTTPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterEventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterModemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.url = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.contentType = mQTTPublisher_ContentTypeFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.topic = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.qos = mQTTPublisher_Data_QoSFromJSON(reader.int32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.username = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.password = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.serverCaCertificateId = longToNumber(reader.int64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 20:
          if (tag === 160) {
            message.tags.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tags.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.overrideUrlValidation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateMQTTPublisherRequest>): CreateMQTTPublisherRequest {
    return CreateMQTTPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateMQTTPublisherRequest>): CreateMQTTPublisherRequest {
    const message = createBaseCreateMQTTPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.description = object.description ?? undefined;
    message.filterEventTypes = (object.filterEventTypes !== undefined && object.filterEventTypes !== null)
      ? Filter_Events.fromPartial(object.filterEventTypes)
      : undefined;
    message.filterModemNumbers = (object.filterModemNumbers !== undefined && object.filterModemNumbers !== null)
      ? Filter_Modems.fromPartial(object.filterModemNumbers)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.url = object.url ?? "";
    message.contentType = object.contentType ?? MQTTPublisher_ContentType.DEFAULT;
    message.topic = object.topic ?? "";
    message.qos = object.qos ?? MQTTPublisher_Data_QoS.DEFAULT;
    message.identifier = object.identifier ?? undefined;
    message.username = object.username ?? undefined;
    message.password = object.password ?? undefined;
    message.certificateId = object.certificateId ?? undefined;
    message.serverCaCertificateId = object.serverCaCertificateId ?? undefined;
    message.disabled = object.disabled ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.overrideUrlValidation = object.overrideUrlValidation ?? undefined;
    return message;
  },
};

function createBaseEnableMQTTPublisherRequest(): EnableMQTTPublisherRequest {
  return { organization: undefined, selection: undefined };
}

export const EnableMQTTPublisherRequest = {
  encode(message: EnableMQTTPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      MQTTPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnableMQTTPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnableMQTTPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = MQTTPublisherSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EnableMQTTPublisherRequest>): EnableMQTTPublisherRequest {
    return EnableMQTTPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EnableMQTTPublisherRequest>): EnableMQTTPublisherRequest {
    const message = createBaseEnableMQTTPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MQTTPublisherSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseEnableMQTTPublisherRequest_Response(): EnableMQTTPublisherRequest_Response {
  return { publishers: [], request: undefined };
}

export const EnableMQTTPublisherRequest_Response = {
  encode(message: EnableMQTTPublisherRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.publishers) {
      MQTTPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      EnableMQTTPublisherRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnableMQTTPublisherRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnableMQTTPublisherRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers.push(MQTTPublisher.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = EnableMQTTPublisherRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EnableMQTTPublisherRequest_Response>): EnableMQTTPublisherRequest_Response {
    return EnableMQTTPublisherRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EnableMQTTPublisherRequest_Response>): EnableMQTTPublisherRequest_Response {
    const message = createBaseEnableMQTTPublisherRequest_Response();
    message.publishers = object.publishers?.map((e) => MQTTPublisher.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? EnableMQTTPublisherRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseDisableMQTTPublisherRequest(): DisableMQTTPublisherRequest {
  return { organization: undefined, selection: undefined };
}

export const DisableMQTTPublisherRequest = {
  encode(message: DisableMQTTPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      MQTTPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DisableMQTTPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisableMQTTPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = MQTTPublisherSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DisableMQTTPublisherRequest>): DisableMQTTPublisherRequest {
    return DisableMQTTPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DisableMQTTPublisherRequest>): DisableMQTTPublisherRequest {
    const message = createBaseDisableMQTTPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MQTTPublisherSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseDisableMQTTPublisherRequest_Response(): DisableMQTTPublisherRequest_Response {
  return { publishers: [], request: undefined };
}

export const DisableMQTTPublisherRequest_Response = {
  encode(message: DisableMQTTPublisherRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.publishers) {
      MQTTPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      DisableMQTTPublisherRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DisableMQTTPublisherRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisableMQTTPublisherRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers.push(MQTTPublisher.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = DisableMQTTPublisherRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DisableMQTTPublisherRequest_Response>): DisableMQTTPublisherRequest_Response {
    return DisableMQTTPublisherRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DisableMQTTPublisherRequest_Response>): DisableMQTTPublisherRequest_Response {
    const message = createBaseDisableMQTTPublisherRequest_Response();
    message.publishers = object.publishers?.map((e) => MQTTPublisher.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? DisableMQTTPublisherRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUpdateMQTTPublisherRequest(): UpdateMQTTPublisherRequest {
  return {
    organization: undefined,
    id: 0,
    deprecatedFilterEventTypes: undefined,
    filterEventTypes: undefined,
    deprecatedFilterModemNumbers: undefined,
    filterModemNumbers: undefined,
    deprecatedFilterTags: undefined,
    filterTags: undefined,
    deprecatedDescription: undefined,
    description: undefined,
    url: undefined,
    contentType: undefined,
    topic: undefined,
    qos: undefined,
    identifier: undefined,
    deprecatedIdentifier: undefined,
    username: undefined,
    deprecatedUsername: undefined,
    password: undefined,
    deprecatedPassword: undefined,
    certificateId: undefined,
    deprecatedCertificateId: undefined,
    serverCaCertificateId: undefined,
    deprecatedServerCaCertificateId: undefined,
    active: undefined,
    deprecatedActive: undefined,
    healthWarningPeriod: undefined,
    healthWarningFailurePercentage: undefined,
    deprecatedHealthWarningFailurePercentage: undefined,
    overrideUrlValidation: undefined,
  };
}

export const UpdateMQTTPublisherRequest = {
  encode(message: UpdateMQTTPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    if (message.deprecatedFilterEventTypes !== undefined) {
      Filter_Events_Update.encode(message.deprecatedFilterEventTypes, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterEventTypes !== undefined) {
      Filter_Events.encode(message.filterEventTypes, writer.uint32(194).fork()).ldelim();
    }
    if (message.deprecatedFilterModemNumbers !== undefined) {
      Filter_Modems_Update.encode(message.deprecatedFilterModemNumbers, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterModemNumbers !== undefined) {
      Filter_Modems.encode(message.filterModemNumbers, writer.uint32(202).fork()).ldelim();
    }
    if (message.deprecatedFilterTags !== undefined) {
      Filter_Tags_Update.encode(message.deprecatedFilterTags, writer.uint32(42).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(210).fork()).ldelim();
    }
    if (message.deprecatedDescription !== undefined) {
      UpdateClearableString.encode(message.deprecatedDescription, writer.uint32(82).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(218).string(message.description);
    }
    if (message.url !== undefined) {
      writer.uint32(90).string(message.url);
    }
    if (message.contentType !== undefined) {
      writer.uint32(96).int32(mQTTPublisher_ContentTypeToNumber(message.contentType));
    }
    if (message.topic !== undefined) {
      writer.uint32(106).string(message.topic);
    }
    if (message.qos !== undefined) {
      writer.uint32(112).int32(mQTTPublisher_Data_QoSToNumber(message.qos));
    }
    if (message.identifier !== undefined) {
      writer.uint32(226).string(message.identifier);
    }
    if (message.deprecatedIdentifier !== undefined) {
      UpdateClearableString.encode(message.deprecatedIdentifier, writer.uint32(122).fork()).ldelim();
    }
    if (message.username !== undefined) {
      writer.uint32(234).string(message.username);
    }
    if (message.deprecatedUsername !== undefined) {
      UpdateClearableString.encode(message.deprecatedUsername, writer.uint32(130).fork()).ldelim();
    }
    if (message.password !== undefined) {
      writer.uint32(242).string(message.password);
    }
    if (message.deprecatedPassword !== undefined) {
      UpdateClearableString.encode(message.deprecatedPassword, writer.uint32(138).fork()).ldelim();
    }
    if (message.certificateId !== undefined) {
      writer.uint32(248).int64(message.certificateId);
    }
    if (message.deprecatedCertificateId !== undefined) {
      UpdateOptionalId.encode(message.deprecatedCertificateId, writer.uint32(146).fork()).ldelim();
    }
    if (message.serverCaCertificateId !== undefined) {
      writer.uint32(256).int64(message.serverCaCertificateId);
    }
    if (message.deprecatedServerCaCertificateId !== undefined) {
      UpdateOptionalId.encode(message.deprecatedServerCaCertificateId, writer.uint32(186).fork()).ldelim();
    }
    if (message.active !== undefined) {
      writer.uint32(264).bool(message.active);
    }
    if (message.deprecatedActive !== undefined) {
      UpdateBoolean.encode(message.deprecatedActive, writer.uint32(154).fork()).ldelim();
    }
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(170).fork()).ldelim();
    }
    if (message.healthWarningFailurePercentage !== undefined) {
      writer.uint32(272).uint32(message.healthWarningFailurePercentage);
    }
    if (message.deprecatedHealthWarningFailurePercentage !== undefined) {
      UpdateZeroableInt.encode(message.deprecatedHealthWarningFailurePercentage, writer.uint32(178).fork()).ldelim();
    }
    if (message.overrideUrlValidation !== undefined) {
      writer.uint32(280).bool(message.overrideUrlValidation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMQTTPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMQTTPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedFilterEventTypes = Filter_Events_Update.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.filterEventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedFilterModemNumbers = Filter_Modems_Update.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.filterModemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedFilterTags = Filter_Tags_Update.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deprecatedDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.description = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.url = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.contentType = mQTTPublisher_ContentTypeFromJSON(reader.int32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.topic = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.qos = mQTTPublisher_Data_QoSFromJSON(reader.int32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.deprecatedIdentifier = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.username = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.deprecatedUsername = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.password = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.deprecatedPassword = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.deprecatedCertificateId = UpdateOptionalId.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.serverCaCertificateId = longToNumber(reader.int64() as Long);
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.deprecatedServerCaCertificateId = UpdateOptionalId.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.deprecatedActive = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.healthWarningFailurePercentage = reader.uint32();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.deprecatedHealthWarningFailurePercentage = UpdateZeroableInt.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.overrideUrlValidation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateMQTTPublisherRequest>): UpdateMQTTPublisherRequest {
    return UpdateMQTTPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMQTTPublisherRequest>): UpdateMQTTPublisherRequest {
    const message = createBaseUpdateMQTTPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    message.deprecatedFilterEventTypes =
      (object.deprecatedFilterEventTypes !== undefined && object.deprecatedFilterEventTypes !== null)
        ? Filter_Events_Update.fromPartial(object.deprecatedFilterEventTypes)
        : undefined;
    message.filterEventTypes = (object.filterEventTypes !== undefined && object.filterEventTypes !== null)
      ? Filter_Events.fromPartial(object.filterEventTypes)
      : undefined;
    message.deprecatedFilterModemNumbers =
      (object.deprecatedFilterModemNumbers !== undefined && object.deprecatedFilterModemNumbers !== null)
        ? Filter_Modems_Update.fromPartial(object.deprecatedFilterModemNumbers)
        : undefined;
    message.filterModemNumbers = (object.filterModemNumbers !== undefined && object.filterModemNumbers !== null)
      ? Filter_Modems.fromPartial(object.filterModemNumbers)
      : undefined;
    message.deprecatedFilterTags = (object.deprecatedFilterTags !== undefined && object.deprecatedFilterTags !== null)
      ? Filter_Tags_Update.fromPartial(object.deprecatedFilterTags)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.deprecatedDescription =
      (object.deprecatedDescription !== undefined && object.deprecatedDescription !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedDescription)
        : undefined;
    message.description = object.description ?? undefined;
    message.url = object.url ?? undefined;
    message.contentType = object.contentType ?? undefined;
    message.topic = object.topic ?? undefined;
    message.qos = object.qos ?? undefined;
    message.identifier = object.identifier ?? undefined;
    message.deprecatedIdentifier = (object.deprecatedIdentifier !== undefined && object.deprecatedIdentifier !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedIdentifier)
      : undefined;
    message.username = object.username ?? undefined;
    message.deprecatedUsername = (object.deprecatedUsername !== undefined && object.deprecatedUsername !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedUsername)
      : undefined;
    message.password = object.password ?? undefined;
    message.deprecatedPassword = (object.deprecatedPassword !== undefined && object.deprecatedPassword !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedPassword)
      : undefined;
    message.certificateId = object.certificateId ?? undefined;
    message.deprecatedCertificateId =
      (object.deprecatedCertificateId !== undefined && object.deprecatedCertificateId !== null)
        ? UpdateOptionalId.fromPartial(object.deprecatedCertificateId)
        : undefined;
    message.serverCaCertificateId = object.serverCaCertificateId ?? undefined;
    message.deprecatedServerCaCertificateId =
      (object.deprecatedServerCaCertificateId !== undefined && object.deprecatedServerCaCertificateId !== null)
        ? UpdateOptionalId.fromPartial(object.deprecatedServerCaCertificateId)
        : undefined;
    message.active = object.active ?? undefined;
    message.deprecatedActive = (object.deprecatedActive !== undefined && object.deprecatedActive !== null)
      ? UpdateBoolean.fromPartial(object.deprecatedActive)
      : undefined;
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.healthWarningFailurePercentage = object.healthWarningFailurePercentage ?? undefined;
    message.deprecatedHealthWarningFailurePercentage =
      (object.deprecatedHealthWarningFailurePercentage !== undefined &&
          object.deprecatedHealthWarningFailurePercentage !== null)
        ? UpdateZeroableInt.fromPartial(object.deprecatedHealthWarningFailurePercentage)
        : undefined;
    message.overrideUrlValidation = object.overrideUrlValidation ?? undefined;
    return message;
  },
};

function createBaseUpdateMQTTPublisherTagsRequest(): UpdateMQTTPublisherTagsRequest {
  return { organization: undefined, selection: undefined, update: undefined };
}

export const UpdateMQTTPublisherTagsRequest = {
  encode(message: UpdateMQTTPublisherTagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      MQTTPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.update !== undefined) {
      UpdateTagsForItem.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMQTTPublisherTagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMQTTPublisherTagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = MQTTPublisherSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateTagsForItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateMQTTPublisherTagsRequest>): UpdateMQTTPublisherTagsRequest {
    return UpdateMQTTPublisherTagsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMQTTPublisherTagsRequest>): UpdateMQTTPublisherTagsRequest {
    const message = createBaseUpdateMQTTPublisherTagsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MQTTPublisherSelection.fromPartial(object.selection)
      : undefined;
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateTagsForItem.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateMQTTPublisherTagsRequest_Response(): UpdateMQTTPublisherTagsRequest_Response {
  return { mqttPublishers: [] };
}

export const UpdateMQTTPublisherTagsRequest_Response = {
  encode(message: UpdateMQTTPublisherTagsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mqttPublishers) {
      MQTTPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMQTTPublisherTagsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMQTTPublisherTagsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mqttPublishers.push(MQTTPublisher.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateMQTTPublisherTagsRequest_Response>): UpdateMQTTPublisherTagsRequest_Response {
    return UpdateMQTTPublisherTagsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMQTTPublisherTagsRequest_Response>): UpdateMQTTPublisherTagsRequest_Response {
    const message = createBaseUpdateMQTTPublisherTagsRequest_Response();
    message.mqttPublishers = object.mqttPublishers?.map((e) => MQTTPublisher.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteMQTTPublisherRequest(): DeleteMQTTPublisherRequest {
  return { organization: undefined, id: 0 };
}

export const DeleteMQTTPublisherRequest = {
  encode(message: DeleteMQTTPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteMQTTPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMQTTPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteMQTTPublisherRequest>): DeleteMQTTPublisherRequest {
    return DeleteMQTTPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteMQTTPublisherRequest>): DeleteMQTTPublisherRequest {
    const message = createBaseDeleteMQTTPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseDeleteMQTTPublisherRequest_Response(): DeleteMQTTPublisherRequest_Response {
  return {};
}

export const DeleteMQTTPublisherRequest_Response = {
  encode(_: DeleteMQTTPublisherRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteMQTTPublisherRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMQTTPublisherRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteMQTTPublisherRequest_Response>): DeleteMQTTPublisherRequest_Response {
    return DeleteMQTTPublisherRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteMQTTPublisherRequest_Response>): DeleteMQTTPublisherRequest_Response {
    const message = createBaseDeleteMQTTPublisherRequest_Response();
    return message;
  },
};

export type MQTTServiceDefinition = typeof MQTTServiceDefinition;
export const MQTTServiceDefinition = {
  name: "MQTTService",
  fullName: "hiber.integration.mqtt.MQTTService",
  methods: {
    list: {
      name: "List",
      requestType: ListMQTTPublishersRequest,
      requestStream: false,
      responseType: ListMQTTPublishersRequest_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateMQTTPublisherRequest,
      requestStream: false,
      responseType: MQTTPublisher,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateMQTTPublisherRequest,
      requestStream: false,
      responseType: MQTTPublisher,
      responseStream: false,
      options: {},
    },
    updateTags: {
      name: "UpdateTags",
      requestType: UpdateMQTTPublisherTagsRequest,
      requestStream: false,
      responseType: UpdateMQTTPublisherTagsRequest_Response,
      responseStream: false,
      options: {},
    },
    enable: {
      name: "Enable",
      requestType: EnableMQTTPublisherRequest,
      requestStream: false,
      responseType: EnableMQTTPublisherRequest_Response,
      responseStream: false,
      options: {},
    },
    disable: {
      name: "Disable",
      requestType: DisableMQTTPublisherRequest,
      requestStream: false,
      responseType: DisableMQTTPublisherRequest_Response,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteMQTTPublisherRequest,
      requestStream: false,
      responseType: DeleteMQTTPublisherRequest_Response,
      responseStream: false,
      options: {},
    },
    history: {
      name: "History",
      requestType: MQTTPublisherHistoryRequest,
      requestStream: false,
      responseType: MQTTPublisherHistoryRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
